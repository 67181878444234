// 内存泄漏检测结果展示
import React from 'react';
import {
  Card,
  Layout,
} from '@tencent/tea-component';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import { TaskInfoCard } from '@/components/taskInfo';
import {
  Tabs,
} from 'tdesign-react';
import JavaHeapResultTab from './overview';
import DomTreeTab from './domtree';
import FeedBackButton from '../../../components/feedBackButton';

const { Body, Content } = Layout;
const JavaHeapResult = (props) => {
  Log.debug('JavaHeapResult', props);
  const { TabPanel } = Tabs;
  return <>
    <Layout className="demo-layout">
      <Body>
        <Content>
          {props.showGobackButton
            ? <Content.Header
              showBackButton
              onBackButtonClick={() => props.goback()}
              title="返回"
            >
            </Content.Header> : ''}
          <Content.Body>
            <TaskInfoCard
              task_info={props.task_info}
              analysis_version={props.analysis_version}
            ></TaskInfoCard>
            <Card>
              <Card.Body
                title="任务结果"
              >
                <FeedBackButton taskID={props.task_info.tid}/>
                <Tabs placement={'top'} size={'medium'} defaultValue={1}>
                  <TabPanel value={1} label="智能分析">
                    <JavaHeapResultTab
                      hprof_result={props.hprof_result}
                    ></JavaHeapResultTab>
                  </TabPanel>
                  <TabPanel value={2} label="支配关系">
                    <DomTreeTab
                      tid={props.task_info.tid}
                    ></DomTreeTab>
                  </TabPanel>
                </Tabs>
              </Card.Body>
            </Card>
          </Content.Body>
        </Content>
      </Body>
    </Layout>
  </>;
};

JavaHeapResult.propTypes = {
  task_info: PropTypes.object,
  goback: PropTypes.func,
  jupyter_url: PropTypes.string,
  hprof_result: PropTypes.array,
  analysis_version: PropTypes.string,
  showGobackButton: PropTypes.bool,
};

JavaHeapResult.defaultProps = {
  jupyter_url: '',
  hprof_result: [],
  analysis_version: '1.0.0',
};

export default JavaHeapResult;


