import React, { useState } from 'react';
import {
  Dialog,
  Form,
  Input,
  Rate,
  Space,
  MessagePlugin,
  Button,
} from 'tdesign-react';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import axios from 'axios';
import { HOST_URL } from '@/config/';
import { MapEditIcon } from 'tdesign-icons-react';
import { goodStatus } from '@/utils/ErrorConfirm';


// 工蜂转发地址
const FeedBackButton = (props) => {
  const [showTaskScoreDialog, setShowTaskScoreDialog] = useState(false);
  const FormItem = Form.FormItem;
  const [form] = Form.useForm();
  const [taskID] = useState(props.taskID);
  Log.debug('FeedBackButton', props);

  const submitRate = (e) => {
    console.log(e);
    if (e.validateResult === false) {
      MessagePlugin.error('提交失败');
    }
    const data = e.fields;
    axios({
      method: 'post',
      url: `${HOST_URL}/api/v1/task/score`,
      data: {
        tid: taskID,
        score: data.rate,
        description: data.description,
      }
    })
      .then((response) => {
        if (goodStatus(response)) {
          MessagePlugin.success('提交成功');
        } else {
          MessagePlugin.error(`提交失败: ${response.msg}`);
        }
      })
      .catch((error) => {
        MessagePlugin.error(`提交失败: ${error}`);
      });
    setShowTaskScoreDialog(false);
  };


  return <div style={{ textAlign: 'right' }}>
  <Button theme="primary" onClick={() => setShowTaskScoreDialog(true)} size="mid">
  反馈帮助我们改善分析结果吧！<MapEditIcon  size="large"/>
    </Button>
  <Dialog
    header="任务反馈"
    visible={showTaskScoreDialog}
    onClose={() => setShowTaskScoreDialog(false)}
    cancelBtn={false}
    confirmBtn={false}
  >
  <Form
    labelAlign="right"
    layout="vertical"
    preventSubmitDefault
    resetType="empty"
    showErrorMessage
    form={form} onSubmit={(e) => submitRate(e)}
  >
    <FormItem
      initialData={0}
      label="评分"
      name="rate"
    >
      <Rate
        showText
        texts={['完全没帮助', '无太大帮助', '一般', '有一定帮助', '很有帮助']}
      />
    </FormItem>
    <FormItem
      initialData=""
      label="描述"
      name="description"
    >
      <Input placeholder="请输入内容" />
    </FormItem>
    <FormItem
      style={{
        marginLeft: 300,
      }}
    >
      <Space>
        <Button type="submit" theme="primary">
          提交
        </Button>
      </Space>
    </FormItem>
  </Form>
  </Dialog>
  </div>;
};

FeedBackButton.propTypes = {
  taskID: PropTypes.string, // 任务id
};

export default FeedBackButton;
