// 容器视角采样结果展示页面
import React, { useEffect, useState } from 'react';
import '../Drop.css';
import Callgraph from './callgraph';
import FlameGraph from '@/components/flamegraph/flamegraph';
import {
  Bubble,
  Card, Icon,
  Layout,
  TabPanel,
  Tabs,
  Text,
} from '@tencent/tea-component';
import {
  TreeSelect,
  Tooltip as TDTooltip,
  Switch,
  List,
  Space,
  Link, Tag, Tooltip, Divider, MessagePlugin,
  Loading, Button,
} from 'tdesign-react';
import PropTypes from 'prop-types';
import { Log } from '@/utils/Log';
import { ThreadTable, HeapStackInfo } from './samplingResult';
import axios from 'axios';
import { HOST_URL } from '@/config/';
import { TaskInfoCard } from '@/components/taskInfo';
import { getFlameGraphURL, downloadFile, jumpTargetConfig } from '@/utils/URL';
import FibonaButton from '@/components/fibona';
import FeedBackButton from '../../components/feedBackButton';
import { FIBONA_TRIGGER } from '@/config';
import { JumpIcon, ThumbUp2Icon, ThumbDown2Icon } from 'tdesign-icons-react';
import qs from 'qs';
import { CallStackViewers } from '../../components/callStack';

const { Body, Content } = Layout;
const { ListItem } = List;

const SamplingResultV2 = (props) => {
  Log.debug('SamplingResultV2', props);
  const [taskData] = useState(props.task_data);
  const [containersInfo] = useState(props.containers_info);
  const [taskResult] = useState(props.task_result);
  const [analysisSuggestions] = useState(props.analysis_suggestions);
  const [containerNameList, setContainerNameList] = useState({});
  const [containerOptions, setContainerOptions] = useState([]);
  const [aiSuggestion, setAiSuggestion] = useState([]);
  const [aiSuggestionStatus, setAiSuggestionStatus] = useState(0);// 0代表不需要更新，1代表正在更新，2代表更新完成，3代表更新失败
  const [initial, setInitial] = useState(false);
  const [selectedContainerName, setSelectedContainerName] = useState('');

  const [cacheTaskResult, setCacheTaskResult] = useState({});

  const [showResultCard, setShowResult] = useState(false);

  useEffect(() => {
    setContainerNameList(initContainerNamelist(props.containers_info));
    setContainerOptions(initContainerOptions(props.containers_info));
    setInitial(true);
  }, []);
  function getTop5Efficient(data) {
    if (!data) {
      return {};
    }
    const top = [];
    for (const [key, value] of Object.entries(data)) {
      if (top.length < 5) {
        top.push([key, value]);
        top.sort((a, b) => b[1] - a[1]);
      } else if (value > top[top.length - 1][1]) {
        top.pop();
        top.push([key, value]);
        top.sort((a, b) => b[1] - a[1]);
      }
    }
    return Object.fromEntries(top);
  }
  useEffect(() => {
    const prompt = {};
    const IDs = [];
    for (let index = 0; index < analysisSuggestions[selectedContainerName].length; index++) {
      const suggestion = analysisSuggestions[selectedContainerName][index];
      IDs.push(suggestion.id);
      if (suggestion?.ai_suggestion) {
        return ;
      }
      const infomation = {};
      infomation['相关调用栈'] = getTop5Efficient(suggestion?.call_stack);
      infomation['采样占比'] = suggestion.suggestion;
      infomation['关键函数'] = suggestion.func;
      prompt[suggestion.id] = infomation;
    }
    setAiSuggestionStatus(1);
    const requestParams = {
      content: prompt,
      ids: IDs,
    };
    axios({
      method: 'post',
      url: `${HOST_URL}/api/v1/task/suggestion/aisuggestion`,
      data: JSON.stringify(requestParams),
    }).then((response) => {
      if (!response?.data?.aiSuggestion) {
        return ;
      }
      const resultList = [];
      const result = JSON.parse(response.data.aiSuggestion);
      for (const key in result) {
        resultList.push(JSON.stringify(result[key]));
      };
      setAiSuggestion(resultList);
      setAiSuggestionStatus(2);
    });
  }, []);

  // 设置最大容忍请求时间，超时显示请求错误
  useEffect(() => {
    if (aiSuggestionStatus === 1) {
    const id = setTimeout(() => {
      if (aiSuggestionStatus === 1) {
        setAiSuggestionStatus(3);
      }
    }, 20000); // 20秒
      return () => clearTimeout(id);
    }
  }, [aiSuggestionStatus]);

  useEffect(() => {
    if (cacheTaskResult[selectedContainerName]) {
      Log.debug('数据在缓存中');
      return ;
    }
    setShowResult(false);
    const result = taskResult[selectedContainerName];
    const params = [
      result?.flamegraph_file,
      result?.callgraph_file,
      result?.extended_flamegraph_file,
    ].filter(value => value !== '' && value !== undefined);
    let requestParams = {};
    if (props.is_shared_url) {
      requestParams = {
        cos_files: params,
        tid: taskData.tid,
        expired_time: props.expired_time,
        token: props.shared_token,
        is_shared_task: '1',
      };
    } else {
      requestParams = {
        cos_files: params,
        tid: taskData.tid,
        is_shared_task: '0',
      };
    }
    axios({
      method: 'get',
      url: `${HOST_URL}/api/v1/cosfiles`,
      params: requestParams,
    }).then((response) => {
      const files = response.data.cos_files;
      const data = cacheTaskResult;
      data[selectedContainerName] = {
        flamegraph_file: files[0],
        callgraph_file: files[1],
        extended_flamegraph_file: files[2],
      };
      setCacheTaskResult(data);
      setShowResult(true);
    });
  }, [selectedContainerName]);

  // 修改suggestion状态
  const changeSuggestionStatus = (suggestion) => {
    Log.debug(suggestion);
    axios({
      method: 'post',
      url: `${HOST_URL}/api/v1/task/suggestion/${suggestion.id}`,
      data: suggestion,
    }).then((response) => {
      Log.debug(response);
      Log.debug('修改状态成功');
    });
  };

  const changeContainerInfoToObject = (item) => {
    const data = {
      label: item,
      value: item,
      content: '',
      imageID: containersInfo[item][2] !== '' ? containersInfo[item][0] : containersInfo[item][1],
      pod: '',
    };
    if (item !== '') {
      if (containersInfo[item][2] === '') {
      // docker
        data.content =  <TDTooltip
          content={`镜像名: ${containersInfo[item][0]}`}
          placement="top-left"
          showArrow
          destroyOnClose>
        {item}
      </TDTooltip>;
      } else {
        // k8s containerd
        // swap containerd info
        data.label = containersInfo[item][0];
        data.content =  <TDTooltip
            content={`所属Pod: ${containersInfo[item][1]}`}
            placement="top-left"
            showArrow
            destroyOnClose>
          {containersInfo[item][0]}
        </TDTooltip>;
        data.pod = containersInfo[item][1];
      }
    } else {
      // host
      data.content =  <TDTooltip
            placement="top-left"
            showArrow
            destroyOnClose>
          全局
        </TDTooltip>;
      data.label = '全局';
    }
    return data;
  };


  // 将容器相关数据转换成option数据
  const initContainerOptions = (containerList) => {
    const res = [];
    const dockerOptions = {
      label: 'Docker容器',
      value: '1',
      children: [],
      disable: true,
    };
    const k8sMap = {};
    const k8sOptions = [];
    const hostOptions = {
      label: '全局',
      value: '2',
      children: [],
      disable: true,
    };
    for (const item in containerList) {
      const result = changeContainerInfoToObject(item);
      if (item === '') {
        hostOptions.children.push(result);
      } else if (result.pod === '') {
        dockerOptions.children.push(result);
      } else {
        if (!(result.pod in k8sMap)) {
          k8sOptions.push({
            label: `Pod:${result.pod}`,
            children: [result],
            value: result.pod,
            disable: true,
          });
          k8sMap[result.pod] = k8sOptions.length - 1;
        } else {
          k8sOptions[k8sMap[result.pod]].children.push(result);
        }
      }
    }
    res.push(hostOptions);
    if (dockerOptions.children.length !== 0) {
      res.push(dockerOptions);
    }
    res.push(...k8sOptions);
    Log.debug('Cascard Options', res);
    return res;
  };

  const initContainerNamelist = (containerList) => {
    const containerNameList = {};
    for (const item in containerList) {
      containerNameList[item] = changeContainerInfoToObject(item);
    }
    return containerNameList;
  };

  const onContainerNameChange = (v) => {
    if (!containerNameList[v]) {
      return ;
    }
    Log.debug('change option', v);
    setSelectedContainerName(v);
  };

  return <div
  className="show-module-style"
>
  <Layout className="demo-layout">
    <Body>
      <Content>
        {props.showGobackButton
          ? <Content.Header
            showBackButton
            onBackButtonClick={() => props.goback()}
            title="返回"
          >
          </Content.Header> : ''}
        <Content.Body>
          <TaskInfoCard
            task_info={taskData}
            analysis_version={props.analysis_version}
            jupyter_url={props.jupyter_url}>
          </TaskInfoCard>
            <Card>
              <Card.Body
              title="任务结果"
              subtitle={(selectedContainerName === '') ? '全局'
                : `容器名: ${containerNameList[selectedContainerName].label} 
              所属Pod: ${containerNameList[selectedContainerName].pod ? containerNameList[selectedContainerName].pod : '无'}`}
              operation =  { (initial)
                ? <TreeSelect
                        value={selectedContainerName}
                        onChange={v => onContainerNameChange(v)}
                        data={containerOptions}
                        filterable
                        filter={(search, option) => option.label.indexOf(search) !== -1}
                        treeProps={{
                          expandAll: false,
                        }}
                      ></TreeSelect> : ''
              }
              >
              { initial && showResultCard ? <TaskResultCardContent
                key={selectedContainerName}
                active_threads_json = {taskResult[selectedContainerName]?.active_threads_json}
                callgraph_file = {cacheTaskResult[selectedContainerName]?.callgraph_file}
                flamegraph_file = {cacheTaskResult[selectedContainerName]?.flamegraph_file}
                extended_flamegraph_file = {cacheTaskResult[selectedContainerName]?.extended_flamegraph_file}
                top_funcs = {taskResult[selectedContainerName]?.top_funcs}
                top_funcs_swapper = {taskResult[selectedContainerName]?.top_funcs_swapper}
                top_funcs_annotate_enable = {taskData?.request_params?.annotate_enable}
                total_records = {taskResult[selectedContainerName]?.total_records}
                total_records_with_swapper = {taskResult[selectedContainerName]?.total_records_with_swapper}
                analyse_suggestions = {analysisSuggestions[selectedContainerName]}
                display_flamegraph_search={new Date(props.task_data.create_time.Time).getTime()
                      > new Date('2023-11-09T10:30:00.000000+08:00').getTime()}
                task_name = {props.task_data.name}
                task_tid = {props.task_data.tid}
                task_type = {props.task_data.profiler_type}
                aisuggestion = {aiSuggestion}
                aiSuggestionStatus = {aiSuggestionStatus}
                change_suggestion_status={changeSuggestionStatus}
              >
              </TaskResultCardContent> : ''}
              </Card.Body>
            </Card>
        </Content.Body>
          </Content>
        </Body>
      </Layout>
    </div>;
};
export default SamplingResultV2;

SamplingResultV2.propTypes = {
  task_data: PropTypes.object,
  containers_info: PropTypes.object,
  analysis_version: PropTypes.string,
  task_result: PropTypes.object,
  analysis_suggestions: PropTypes.object,
  goback: PropTypes.func,
  is_shared_url: PropTypes.bool,
  jupyter_url: PropTypes.string,
  expired_time: PropTypes.string,
  shared_token: PropTypes.string,
  showGobackButton: PropTypes.bool,
};

// taskResultCard 负责展示任务结果信息
const TaskResultCardContent = (props) => {
  const [showExtendedFlamegraph, setShowExtendedFlameGraph] = useState(false);

  Log.debug('TaskResultCard', props);
  // 基于传入数据生成tabs
  const tabs = [];
  // 分析建议永远显示，如果没有建议也会显示"暂无建议"
  tabs.push({
    id: 'suggestions', label: <div>
      <Text>分析建议 </Text>
      <Bubble
          arrowPointAtCenter
          content="分析流程已去除swapper等无效数据"
      ><Icon type="info" style={{ marginBottom: 3 }}/></Bubble>
    </div>,
  });

  if (props.active_threads_json) {
    tabs.push({
      id: 'thread_data', label: '活跃线程',
    });
  }
  if (props.top_funcs !== '') {
    tabs.push({
      id: 'topN', label: '热点函数',
    });
  }
  if (props.flamegraph_file) {
    tabs.push({
      id: 'flamegraph', label: '火焰图',
    });
  }
  if (props.callgraph_file) {
    tabs.push({
      id: 'callgraph', label: '调用图',
    });
  }

  let topNData = JSON.parse(props.top_funcs);
  // 先按照值对object排序
  topNData = Object.entries(topNData).sort((a, b) => b[1] - a[1]);

  let topNDataSwapper;
  if (props.top_funcs_swapper) {
    topNDataSwapper = JSON.parse(props.top_funcs_swapper);
    topNDataSwapper = Object.entries(topNDataSwapper).sort((a, b) => b[1] - a[1]);
  }

  // 添加建议展示
  // TODO(alexjyliu): 未来可能展示更多的建议字段（分类、优先级）
  const suggestions = [];
  if (props.analyse_suggestions?.length > 0) {
    for (let index = 0; index < props.analyse_suggestions.length; index++) {
      const suggestion = props.analyse_suggestions[index];
      const aisuggestion = props.aisuggestion[index];
      suggestions.push(
      <><ListItem action={
        <Space>
          {suggestion?.func !== '' && suggestion?.func !== undefined
          ? <>
          <Divider align="center" layout="vertical" style={{ height: '30px', marginRight: '-10px' }}></Divider>
          <Space>
          <Button style={{ marginRight: '-7px' }} shape="circle" variant="text"
          onClick = {() => {
            props.change_suggestion_status({
              id: suggestion.id,
              suggestion_status: 10,
              suggestion_tapd: false,
            });
            MessagePlugin.success('感谢您的反馈！', 2 * 1000);
          }}
          >
          <ThumbUp2Icon />
        </Button>
        <Button style={{ marginLeft: '-7px' }} shape="circle" variant="text"
        onClick = {() => {
          props.change_suggestion_status({
            id: suggestion.id,
            suggestion_status: 20,
            suggestion_tapd: false,
          });
          MessagePlugin.success('感谢您的反馈！', 2 * 1000);
        }}
        >
        <ThumbDown2Icon />
        </Button>
        </Space>
          {/* <Link
              theme="primary" hover="color" onClick={() => {
                props.change_suggestion_status({
                  id: suggestion.id,
                  suggestion_tapd: true,
                  suggestion_status: suggestion.status,
                });
              }}>
            Tapd
          </Link> */}
          {/* <Link
              href={getFlameGraphURL(window.location, suggestion.func).toString()}
              theme="primary" hover="color" target={jumpTargetConfig()}>
            火焰图
          </Link> */}
          </> : ''}
        </Space>
      }>
        <Space  direction = "vertical" size={2}>
        <Tooltip theme="light" content={
          <FibonaButton plain title="分析建议调优" trigger={FIBONA_TRIGGER.collection} data={() => suggestion.suggestion}></FibonaButton>
        }>
          <Tag size="large" theme="danger" variant="light" style={{ wordWrap: 'break-word', whiteSpace: 'normal', height: 'auto' }}>{
            <>
            {suggestion.suggestion}
            <>点击</>
             <Link
             href={getFlameGraphURL(window.location, suggestion.func).toString()}
             theme="primary" hover="color" target={jumpTargetConfig()}>
           查看火焰图
         </Link>
         <>进一步分析</>
         </>
            }</Tag>
        </Tooltip>
        <>
        {props.aiSuggestionStatus === 0 ? <span style={{ color: 'grey' }}>{suggestion?.ai_suggestion}</span>
        : props.aiSuggestionStatus === 1 ? <Loading loading={true} text="AI建议生成中..." size="small"></Loading>
        : props.aiSuggestionStatus === 2 ? <span style={{ color: 'grey'}}>{aisuggestion}</span>
        : <span style={{ color: 'grey' }}>(T ^ T)可能出现网络问题，AI建议生成失败</span>}
        </>
        </Space>
      </ListItem>
      { (suggestion.call_stack && suggestion.call_stack != "{}")
        ? <ListItem>
          <CallStackViewers stacksObjects={suggestion.call_stack}/>
        </ListItem> : ""
      }
      </>);
    }
  } else {
    suggestions.push(<ListItem><Tag size="large" theme="success" variant="light">暂无建议</Tag></ListItem>);
  }

  // 如果存在火焰图搜索query，则该页面来自其他页面的跳转，直接切换至火焰图tab
  let initActiveTab = tabs[0].id;
  let flamegraphFile = props.flamegraph_file;
  const flamegraphSearch = new URLSearchParams(window.location.search).get('flamegraph_search');
  if (flamegraphSearch) {
    initActiveTab = 'flamegraph';

    // svg 位于 object 标签内，svg内部无法获取到windows.location.search，所以此处需要透传该参数至svg内部
    const u = new URL(props.flamegraph_file);
    u.searchParams.set('flamegraph_search', flamegraphSearch);
    flamegraphFile = u.toString();
  }
  const downloadFlamegraphFileName = `${props.task_name}-flamegraph.svg`;
  return <>
    {/* <List>
      {suggestions}
    </List> */}
    <FeedBackButton taskID={props.task_tid}/>
    <Tabs tabs={tabs} defaultActiveId={initActiveTab}>
      <TabPanel id="suggestions">
        <List size="large"  split>
        {suggestions}
        </List>
      </TabPanel>
      <TabPanel id="thread_data">
        <ThreadTable thread_data={JSON.parse(props.active_threads_json)}></ThreadTable>
      </TabPanel>
      <TabPanel id="topN">
        <HeapStackInfo
          TaskType = {props.task_type}
          TaskID = {props.task_tid}
          HeapStackInfo={topNData}
          HeapStackInfoSwapper={topNDataSwapper}
          annotate_enable={props.top_funcs_annotate_enable}
          total_records = {props.total_records}
          total_records_with_swapper = {props.total_records_with_swapper}
          display_flamegraph_search={props.display_flamegraph_search}></HeapStackInfo>
      </TabPanel>
      <TabPanel id="flamegraph">
        { props.extended_flamegraph_file === '' ? '' : <Switch size="large" label={['增强火焰图开启', '增强火焰图关闭']}
        value={ showExtendedFlamegraph } defaultValue={false}
        onChange={x => setShowExtendedFlameGraph(x)} />}
        { props.flamegraph_file === '' ? ''
          : <>`
            <Link style={{ float: 'right', padding: '0 10px' }}
                  onClick={() => window.open(`/taskCompare?${qs.stringify({ task1: props.task_tid })}`)}
                  theme="primary"
                  suffixIcon={<JumpIcon />}>对比火焰图</Link>
            <Link style={{ float: 'right' }}
                  onClick={() => downloadFile(downloadFlamegraphFileName, props.flamegraph_file)}
                  theme="primary">下载火焰图</Link>
          </>
        }
        { !showExtendedFlamegraph ? <div>
            <object width="100%" style={{ margin: '0  auto', display: 'block' }}
                    id="object" data={flamegraphFile}
                    type="image/svg+xml">FlameGraph</object>
        </div>
          : <div>
          <FlameGraph
            flamegraphURL={props.extended_flamegraph_file}
          />
        </div> }
      </TabPanel>
      { props.callgraph_file === '' ? '' : <TabPanel id="callgraph">
          <div>
            <Callgraph
              callgraphURL={props.callgraph_file}
              defaultNodeCnt={80}
            />
          </div>
        </TabPanel>
      }
    </Tabs>
    </>;
};

TaskResultCardContent.propTypes = {
  active_threads_json: PropTypes.string,
  callgraph_file: PropTypes.string,
  flamegraph_file: PropTypes.string,
  extended_flamegraph_file: PropTypes.string,
  top_funcs: PropTypes.string,
  top_funcs_swapper: PropTypes.string,
  analyse_suggestions: PropTypes.array,
  top_funcs_annotate_enable: PropTypes.bool,
  total_records: PropTypes.string,
  total_records_with_swapper: PropTypes.string,
  display_flamegraph_search: PropTypes.bool,
  task_name: PropTypes.string,
  container_name: PropTypes.string,
  task_tid: PropTypes.string,
  task_type: PropTypes.number,
  change_suggestion_status: PropTypes.func,
  aisuggestion: PropTypes.array,
  aiSuggestionStatus: PropTypes.number,
};
