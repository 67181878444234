import { CheckCircleIcon, CloseCircleIcon, TimeIcon, TagIcon, LoadingIcon } from 'tdesign-icons-react';
import React from 'react';
export const DEBUG = process.env.NODE_ENV === 'development';
export const TEST_ENV = process.env.REACT_APP_ENV !== 'build';

// 由 public/config.js 动态注入
export const HOST_URL = window?.config?.HOST_URL;
export const GongFengHOST = window?.config?.GongFengHOST;
export const ERROR_CODE_MSG = window?.config?.ERROR_CODE_MSG;
export const MALLOC_FUNCS = window?.config?.MALLOC_FUNCS;
export const AGENT_DOCKER_RUN_HOST = window?.config?.AGENT_DOCKER_RUN_HOST;
export const AGENT_DOCKER_RUN_PORT = window?.config?.AGENT_DOCKER_RUN_PORT;
export const AGENT_SSL_ENABLED = window?.config?.AGENT_SSL_ENABLED;
export const K8S_IMAGE_NAME = window?.config?.K8S_IMAGE_NAME;
export const TASK_TYPE_HELP = window?.config?.TASK_TYPE_HELP;
export const PRODUCT_NAME = 'drop';
export const PRODUCT_PREFIX = `${PRODUCT_NAME}_`;

export const FIBONA_APPID = '5cd0f40f2a69fc08';
export const FIBONA_INSTALL_URL = 'https://chrome.google.com/webstore/detail/fibona/jojigmehaiphbljmilpibfgdkhadbdnn?hl=zh-CN&authuser=0';
export const FIBONA_HELP_URL = 'https://iwiki.woa.com/p/4009438183';
export const FIBONA_TRIGGER = {
  topN: 11, // 热点函数
  asm: 12, // 热点汇编
  collection: 13, // 知识库关联
  golangmem: 24,
};


//  status    analysis_status    对应状态
//   0                              新建
//   1                              执行中
//   3                              执行失败
//   2              0               执行成功，待分析
//   2              1               执行成功，分析中
//   2              2               执行成功，分析成功
//   2              3               执行成功，分析失败
//   4专为复合任务生成
//   4              0               执行完成了一个检查点
//   4              1               有部分检查点分析成功
//   4              2               全部分析完成
export const TASK_STATUS_INFO = {
  0: <><TagIcon/> <span>{<b>新建</b>}</span></>,
  10: <><LoadingIcon/> <span >{<b>执行中</b>}</span></>,
  20: <><TimeIcon/> <span>{<b>执行成功，待分析</b>}</span></>,
  21: <><LoadingIcon/> <span >{<b>执行成功，分析中</b>}</span></>,
  22: <><CheckCircleIcon style={{ color: '#008858' }} /> <span >{<b>执行成功，分析成功</b>}</span></>,
  23: <><CloseCircleIcon color="red"/> <span style={{ color: 'red' }}>{<b>执行成功，分析失败</b>}</span></>,
  30: <><CloseCircleIcon color="red"/> <span style={{ color: 'red' }}>{<b>执行失败</b>}</span></>,
  40: <><LoadingIcon/> <span >{<b>执行与分析中</b>}</span></>,
  41: <><LoadingIcon/> <span >{<b>部分检查点成功,执行与分析中</b>}</span></>,
  42: <><CheckCircleIcon  style={{ color: '#008858' }}/> <span >{<b>全部完成</b>}</span></>,
};

export const CODE_TO_TASK_STATUS_INFO = (codestr) => {
  if (codestr in TASK_STATUS_INFO) {
    return TASK_STATUS_INFO[codestr];
  } else {
    return TASK_STATUS_INFO[10]; // 默认执行中
  }
};

export const TASK_TYPE_MAP = {
  0: '通用分析',
  1: 'Java采样分析',
  2: '磁盘追踪',
  3: '时序分析',
  4: '虚拟内存泄漏检测',
  5: 'Golang内存分析',
  6: 'Java堆分析',
  7: '物理内存泄漏检测',
  8: 'Jeprof内存分析',
  '-1': '动态检测',
};


export const CODE_TO_MESSAGE = (codestr) => {
  if (codestr in ERROR_CODE_MSG) {
    return ERROR_CODE_MSG[codestr];
  }
  return codestr;
};

export const TASK_NAME_MAP = {
  ProfilingGeneralTask: 0,
  ProfilingJavaTask: 1,
  ProfilingTimingAnalysisTask: 3,
  TracingDiskTraceTask: 2,
  TracingMemLeakTask: 4,
  PprofMemLeakTask: 5,
  TracingJavaHeapTask: 6,
  PageFaultTask: 7,
  JeprofTask: 8,
  DynamicDetection: -1,
};


// 功能最低版本
// eg: 1.1.0 => 10100 1.2.45 => 10245
export const FEATURE_MINIMAL_VERSION = {
  profilingByContainer: 10100,
  annotate: 10201,
  record_process: 10207,
  dwarf_lbr_callgraph_option: 10208,
  pprof_profiling: 10300,
  resource_precheck: 10301,
};

// agent环境自检功能表
export const MACHINE_FEATURES = {
  // wide option
  OPTION_PORT: 1000000,
  OPTION_COMM: 1000100,
  OPTION_PID_SUBPROCESS: 1000200,
  OPTION_CONTAINER_MODE: 1000300,
  OPTION_COMMAND: 1000400,
  // hotmethod
  PERF_ENABLE: 2000000,
  PERF_RECORD_ENABLE: 2000100,
  PERF_SCRIPT_ENABLE: 2000200,
  PERF_ANNOTATE_ENABLE: 2000300,
  ASYNC_PROFILER_ENABLE: 2000400,
  PPROF_ENABLE: 2000500,
  // bcc tracing
  BCC_ENABLE: 3000100,
  MEMLEAK_ENABLE: 3000200,
  PAGE_FAULT_ENABLE: 3000300,
  BIO_ENABLE: 3000400,
};

